import { useOUserClient } from "@grpc/organizationUserClient";
import { defineStore } from "pinia";
import {
    BaseEntityStorage,
    storeEntities,
    storeGetByID,
    storeGetMissedIDs,
} from "@app/stores/converters/repo";
import { OrganizationUser } from "@app/entities/organzationUser";
import { OrganizationID, OrganizationUserID } from "@app/entities/uuid";
import { Context } from "@app/entities/context";
import { UserRole } from "@app/entities/userRole";
import { removeUndefined } from "@app/services/arrays";

export interface OrganizationUserClient {
    loadByOrganizationID(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<OrganizationUser[]>;

    loadEmployees(
        ctx: Context,
        organizationID: OrganizationID,
    ): Promise<OrganizationUser[]>;

    loadByID(
        ctx: Context,
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined>;

    store(
        ctx: Context,
        _organizationID: OrganizationID,
        nickname: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string,
        password: string,
        role: UserRole,
    ): Promise<OrganizationUser | undefined>;

    update(
        ctx: Context,
        id: OrganizationUserID,
        role: UserRole,
    ): Promise<OrganizationUser | undefined>;

    destroy(
        ctx: Context,
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined>;

    restore(
        ctx: Context,
        id: OrganizationUserID,
    ): Promise<OrganizationUser | undefined>;
}

type StoreFields = BaseEntityStorage<OrganizationUser>;

const client: OrganizationUserClient = useOUserClient();

export const useOrganizationUsersRepo = defineStore("organizationUser", {
    state: () =>
        ({
            data: [],
            byId: {},
            byPage: {},
            currentPageId: undefined,
            lastUpdate: new Date(),
        }) as StoreFields,
    actions: {
        sync(entities: OrganizationUser[]) {
            return storeEntities(this, entities);
        },
        async loadByIDs(
            ctx: Context,
            entitiesIDs: OrganizationUserID[],
        ): Promise<OrganizationUser[]> {
            const waits: Promise<OrganizationUser | undefined>[] = [];
            entitiesIDs.forEach((value) => {
                waits.push(this.loadByID(ctx, value));
            });
            const data = await Promise.all(waits);
            return removeUndefined(data);
        },

        async loadByOrganizationID(
            ctx: Context,
            organizationID: OrganizationID,
        ): Promise<OrganizationUser[]> {
            const data = await client.loadByOrganizationID(ctx, organizationID);
            this.sync(data);
            return data;
        },

        async loadEmployees(
            ctx: Context,
            organizationID: OrganizationID,
        ): Promise<OrganizationUser[]> {
            const data = await client.loadEmployees(ctx, organizationID);
            this.sync(data);
            return data;
        },

        async loadByID(
            ctx: Context,
            id: OrganizationUserID,
        ): Promise<OrganizationUser | undefined> {
            const data = await client.loadByID(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async store(
            ctx: Context,
            organizationID: OrganizationID,
            nickname: string,
            firstName: string,
            lastName: string,
            phone: string,
            email: string,
            password: string,
            role: UserRole,
        ): Promise<OrganizationUser | undefined> {
            const data = await client.store(
                ctx,
                organizationID,
                nickname,
                firstName,
                lastName,
                phone,
                email,
                password,
                role,
            );
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async update(
            ctx: Context,
            id: OrganizationUserID,
            role: UserRole,
        ): Promise<OrganizationUser | undefined> {
            const data = await client.update(ctx, id, role);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async destroy(
            ctx: Context,
            id: OrganizationUserID,
        ): Promise<OrganizationUser | undefined> {
            const data = await client.destroy(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },

        async restore(
            ctx: Context,
            id: OrganizationUserID,
        ): Promise<OrganizationUser | undefined> {
            const data = await client.restore(ctx, id);
            if (data) {
                this.sync([data]);
            }
            return data;
        },
    },
    getters: {
        All: (store) =>
            store.data.sort((a, b) => {
                if (a.sort === b.sort) {
                    return 0
                }
                return a.sort > b.sort ? 1 : -1
            }),
        AllActive: (store) => 
            store.data.filter((value) => !value.isDeleted),
        getByID: (store) => storeGetByID<OrganizationUser>(store),
        getMissedIDs: (store) =>
            storeGetMissedIDs<OrganizationUser, OrganizationUserID>(store),
        trainers(state): OrganizationUser[] {
            return state.data.filter(
                (user) =>
                    user.role === UserRole.TEACHER ||
                    user.role === UserRole.OWNER,
            );
        },
    },
});
