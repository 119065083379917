import { User } from "@app/entities/user";
import { UserRole } from "@app/entities/userRole";
import { OrganizationUserID, UserID } from "@app/entities/uuid";

export type OrganizationUser = {
    id: OrganizationUserID;
    userID: UserID;
    user: User;
    role: UserRole;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    isDeleted: boolean;
    sort: string
};

export const oUserName = (u?: OrganizationUser): string => {
    if (u) {
        if (u.user.firstName && u.user.lastName) {
            return `${u.user.firstName} ${u.user.lastName} (${u.user.nickname})`;
        } else {
            return u.user.nickname;
        }
    } else {
        return "";
    }
};


export const isEmployee = (oUser?: OrganizationUser): boolean => {
    return (
        !!oUser &&
        (oUser.user.isAdmin ||
            oUser.role === UserRole.OWNER ||
            oUser.role === UserRole.TEACHER ||
            oUser.role === UserRole.MANAGER)
    );
};

export const isOwner = (oUser?: OrganizationUser): boolean => {
    return (
        !!oUser && (oUser.user.isAdmin || oUser.role === UserRole.OWNER)
    );
};

export const isManager = (oUser?: OrganizationUser): boolean => {
    return (
        !!oUser &&
        (oUser.user.isAdmin ||
            oUser.role === UserRole.OWNER ||
            oUser.role === UserRole.MANAGER)
    );
};

export const isAdmin = (oUser?: OrganizationUser): boolean => {
    return !!oUser && oUser.user.isAdmin;
};